<template>
  <div
    v-if="!spinnerStore.active && broadcastPage"
  >
    <teleport-wrapper
      to="#breadcrumbs"
    >
      <app-breadcrumb
        :breadcrumbs="breadcrumbs"
        class="mb-4"
      />
    </teleport-wrapper>

    <teleport-wrapper
      to="#mainTitle"
    >
      {{ t('breadcrumbs.broadcast.page_edition') }}
    </teleport-wrapper>

    <copy-to-clipboard
      :url="broadcastPage.attributes?.url"
    />

    <app-card-item
      class="p-4 max-w-screen-md mt-8"
    >
      <broadcast-form
        :resource="broadcastPage"
        :submitting="broadcastPageFormSubmitting"
        form-mode="edit"
        :additional-errors="formErrors"
        @submitted="handleSubmit"
      />
    </app-card-item>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useSpinnerStore } from '@shared/store/spinner'
import { useFlashesStore } from '@shared/store/flashes'
import { fetchApiBroadcastPage, updateBroadcastPage } from '@shared/http/api'
import breadcrumbBroadcastPage from '@extranet/breadcrumbs/broadcast_page'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import BroadcastForm from '@extranet/components/resources/broadcast_page/BroadcastPageForm.vue'
import CopyToClipboard from '@extranet/components/resources/broadcast_page/CopyToClipboard.vue'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const spinnerStore = useSpinnerStore()
const flashStore = useFlashesStore()

// ---------- BROADCAST_PAGE ----------

const broadcastPageFormSubmitting = ref(false)
const broadcastPage = ref(null)
const formErrors = ref({})

// ---------- FETCH DATA ----------

function fetchBroadcastPage() {
  spinnerStore.enable()

  const { id } = route.params
  const params = {
    'mode': 'edit',
  }

  fetchApiBroadcastPage(id, params)
    .then((response) => {
      broadcastPage.value = response.data.data

      if (!broadcastPage.value?.authorizations?.update) {
        router.push({ name: 'dashboard' })

        flashStore.addFlash({
          message: t('exceptions.403'),
          type: 'danger',
        })
      }
    })
    .catch((e) => {
      if (e.response?.data?.errors) {
        formErrors.value = e.response.data.errors
      }
    })
    .finally(() => {
      spinnerStore.disable()
    })
}

// ----------- FORM SUBMIT -----------

function handleSubmit(attributes) {
  if (!broadcastPageFormSubmitting.value) {
    broadcastPageFormSubmitting.value = true

    const params = {
      data: {
        type: 'broadcastpages',
        attributes: { ...attributes },
      },
    }

    updateBroadcastPage(broadcastPage.value.id, params)
      .then((response) => {
        const pointOfInterest = response.data.data.relationships.point_of_interest
        router.push({ name: 'show-points-of-interest', params: { id: pointOfInterest.id } })

        flashStore.addFlash({
          message: t('broadcast.notifications.updated'),
          type: 'success',
        })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        broadcastPageFormSubmitting.value = false
      })
  }
}

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbBroadcastPage()

const breadcrumbs = computed(() => (
  show(broadcastPage.value)
))

// ---------- LIFECYCLES ----------

onBeforeMount(() => {
  fetchBroadcastPage()
})
</script>
